import { downloadCSV } from "react-admin";
import { get } from "lodash";
import jsonExport from "jsonexport/dist";
import moment from "moment";

const exporter = (data, translate) => {
  const columnName = (name) => translate(`resources.reminders.fields.${name}`);
  const dateFormat = translate("app.date_format.short");
  const ordersToExport = data.map((reminder) => ({
    [columnName("id")]: get(reminder, "id"),
    [columnName("customer_id")]: get(reminder, "customer.fullname"),
    [columnName("customer_employee_id")]: get(
      reminder,
      "customer_employee.fullname"
    ),
    [columnName("customer_office_id")]: get(reminder, "customer_office.name"),
    [columnName("reminder_category_id")]: get(
      reminder,
      "reminder_category.name"
    ),
    [columnName("status")]: ((reminder) => {
      const status = get(reminder, "status");
      const label = translate(`resources.reminders.status.${status}`);
      return label;
    })(reminder),
    [columnName("last_check")]: moment(get(reminder, "last_check")).format(
      dateFormat
    ),
    [columnName("expiry_date")]: moment(get(reminder, "expiry_date")).format(
      dateFormat
    ),
    [columnName("reminder_date")]: moment(
      get(reminder, "reminder_date")
    ).format(dateFormat),
  }));
  jsonExport(
    ordersToExport,
    {
      rowDelimiter: ";",
      headers: [
        columnName("id"),
        columnName("customer_id"),
        columnName("customer_employee_id"),
        columnName("customer_office_id"),
        columnName("reminder_category_id"),
        columnName("status"),
        columnName("expiry_date"),
        columnName("reminder_date"),
      ],
    },
    (err, csv) => downloadCSV("\uFEFF" + csv, "reminders")
  );
};

export default exporter;
