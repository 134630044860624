import { downloadCSV } from "react-admin";
import { get } from "lodash";
import jsonExport from "jsonexport/dist";

const exporter = (data, translate) => {
  const columnName = (name) =>
    translate(`resources.customer-employees.fields.${name}`);
  const ordersToExport = data.map((customerEmployee) => ({
    [columnName("id")]: get(customerEmployee, "id"),
    [columnName("customer_id")]: get(customerEmployee, "customer.fullname"),
    [columnName("name")]: get(customerEmployee, "name"),
    [columnName("surname")]: get(customerEmployee, "surname"),
    [columnName("fiscal_code")]: get(customerEmployee, "fiscal_code"),
    [columnName("email")]: get(customerEmployee, "email"),
    [columnName("phone")]: get(customerEmployee, "phone"),
  }));
  jsonExport(
    ordersToExport,
    {
      rowDelimiter: ";",
      headers: [
        columnName("id"),
        columnName("customer_id"),
        columnName("name"),
        columnName("surname"),
        columnName("fiscal_code"),
        columnName("email"),
        columnName("phone"),
      ],
    },
    (err, csv) => downloadCSV("\uFEFF" + csv, "customer-employees")
  );
};

export default exporter;
